import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

import { FeatureType, KPI_FORMAT } from 'common/constants'
import { getFormat } from 'common/helpers-tick-formater'
import { formatNumber } from 'common/helpers-format-number'
import { getKpiValueAndSign } from 'common/helpers'

import CustomTooltip from './sources-bar-chart-tooltip'
import BarchartTotal from './sources-bar-chart-total'

import { Box, useMediaQuery, useTheme } from '@mui/material'

interface CustomSourcesBarChartProps {
  data: any[]
  dimension: string
  kpi: { id: string; title: string; format: string }
  total: number
  highestNumber?: number
  format: keyof typeof KPI_FORMAT
  color: string
  isKpi3?: boolean
  sortConfig: {
    kpiId: string
    kpiTitle: string
    ascending: boolean
  }
  handleSortConfig: any
}

const CustomSourcesBarChart: React.FC<CustomSourcesBarChartProps> = ({
  data,
  dimension,
  kpi,
  total,
  highestNumber = 0,
  format,
  color,
  isKpi3,
  sortConfig,
  handleSortConfig,
}) => {
  const theme = useTheme()

  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'))
  const {
    value: { currency },
  } = useAppSelector(selectAuth)
  const { i18n } = useTranslation()
  const currentlanguage = i18n.language.split('-')[0]

  const formatKpiValue = (tickItem: number) => {
    const formattedNumber =
      tickItem !== 0 && !tickItem
        ? '-'
        : formatNumber(highestNumber, Number(tickItem), currentlanguage)

    const valueAndSign = getKpiValueAndSign(
      tickItem,
      format,
      currentlanguage,
      currency
    )
    const sign = valueAndSign.split(' ')[1] || ''

    return `${formattedNumber}${sign}`
  }
  const formatRelativeKpiPercent = (tickItem: number) => `${tickItem}%`
  const isTrafficSource = dimension === FeatureType.TrafficSource

  const CustomizedTick = (tickItem: string) => {
    const countUpperCase = (str: string) => {
      return (str.match(/[A-Z]/g) || []).length
    }

    const uppercaseCount = countUpperCase(tickItem)
    const isTruncateNeeded = tickItem.length > 13 || uppercaseCount >= 6
    const maxLength = uppercaseCount >= 6 ? 7 : 12

    return isTruncateNeeded ? `${tickItem.slice(0, maxLength)}..` : tickItem
  }
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <BarchartTotal
        kpi={kpi}
        total={total}
        format={format}
        handleSortConfig={handleSortConfig}
        sortConfig={sortConfig}
      />
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          layout="vertical"
          style={{ padding: theme.spacing(0.5) }}
        >
          <XAxis
            type="number"
            tick={{
              fontSize: theme.typography.caption.fontSize,
              fill: theme.palette.text.primary,
            }}
            tickFormatter={getFormat(kpi.format, currency)}
            minTickGap={20}
          />
          <YAxis
            dataKey={dimension}
            type="category"
            orientation="left"
            tickLine={false}
            axisLine={false}
            width={120}
            tickMargin={119}
            tick={{
              fontSize: theme.typography.body1.fontSize,
              fill: theme.palette.text.primary,
              textAnchor: 'start',
            }}
            tickFormatter={CustomizedTick}
          />
          {!isKpi3 ? (
            <>
              <YAxis
                dataKey={kpi.id}
                type="category"
                orientation="right"
                yAxisId="right-value"
                width={64}
                tickMargin={0}
                dx={64}
                dy={0}
                tickLine={false}
                axisLine={false}
                tick={{
                  fontSize: theme.typography.body1.fontSize,
                  fill: theme.palette.text.primary,
                  textAnchor: 'end',
                }}
                tickFormatter={formatKpiValue}
              />
              <YAxis
                dataKey={`relative_${kpi.id}_percent`}
                type="category"
                orientation="right"
                yAxisId="right-relative-percentage"
                width={1}
                tickMargin={-64}
                dx={64}
                dy={16}
                tickLine={false}
                axisLine={false}
                tick={{
                  fontSize: theme.typography.caption.fontSize,
                  fill: theme.palette.text.primary,
                  textAnchor: 'end',
                }}
                tickFormatter={formatRelativeKpiPercent}
              />
            </>
          ) : (
            <YAxis
              dataKey={kpi.id}
              type="category"
              orientation="right"
              yAxisId="right-value"
              width={64}
              tickMargin={63}
              tickLine={false}
              axisLine={false}
              tick={{
                fontSize: theme.typography.body1.fontSize,
                fill: theme.palette.text.primary,
                textAnchor: 'end',
              }}
              tickFormatter={getFormat(kpi.format, currency, currentlanguage)}
            />
          )}

          <CartesianGrid horizontal={false} />
          <Tooltip
            content={
              <CustomTooltip
                kpiFormat={kpi.format}
                currency={currency}
                isTrafficSource={isTrafficSource}
                isKpi3={isKpi3 || false}
              />
            }
            cursor={{ fill: theme.palette.background.paper2 }}
            wrapperStyle={{
              zIndex: 1,
            }}
            isAnimationActive
            offset={isWideScreen ? 10 : -100}
            reverseDirection={{ x: isWideScreen && isKpi3, y: false }}
            allowEscapeViewBox={{ x: true, y: false }}
          />
          <Bar
            dataKey={kpi.id}
            fill={color}
            radius={8}
            barSize={20}
            isAnimationActive
            animationDuration={500}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default CustomSourcesBarChart
