import { useLayoutEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectTheme, setMode } from 'store/features/theme-slice'

import { getIsRememberMe, getPersistedItem, persistItem } from 'common/helpers'
import { MODE } from 'common/constants'

import { Button } from '@mui/material'

const ChangeMode = () => {
  const dispatch = useAppDispatch()

  const { mode } = useAppSelector(selectTheme)
  const persistedTheme = getPersistedItem('theme')

  const [isDark, setIsDark] = useState(persistedTheme?.mode === MODE.dark)

  useLayoutEffect(() => {
    dispatch(setMode(isDark ? MODE.dark : MODE.light))
    // eslint-disable-next-line
  }, [])

  const handleMode = () => {
    const isRememberMe = getIsRememberMe()

    setIsDark((prev) => !prev)
    dispatch(setMode(isDark ? MODE.light : MODE.dark))

    persistItem(
      'theme',
      {
        ...persistedTheme,
        mode: isDark ? MODE.light : MODE.dark,
      },
      isRememberMe
    )
  }

  return (
    <Button
      onClick={handleMode}
      variant="contained"
      sx={{
        minWidth: '136px',
        borderRadius: '20px',
      }}
    >
      {mode === MODE.light ? 'Dark mode' : 'Light Mode'}
    </Button>
  )
}

export default ChangeMode
