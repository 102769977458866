import {
  CURRENCY,
  FeatureType,
  KPI_FORMAT,
  PackageType,
  PageType,
} from './constants'

export const getIsRememberMe = () => {
  if (typeof window === 'undefined') return false
  return localStorage.getItem('isRememberMe') === 'true'
}

export const persistItem = (
  name: string,
  data: any,
  isRememberMe: boolean = false,
  isLogout: boolean = false
) => {
  try {
    if (typeof window === 'undefined') return

    const storage = isRememberMe ? localStorage : sessionStorage
    storage.setItem(name, JSON.stringify(data))

    if (!isLogout && isRememberMe) {
      localStorage.setItem('isRememberMe', 'true')
    }
    if (isLogout) {
      localStorage.removeItem('isRememberMe')
    }
  } catch (err: any) {
    throw new Error(err)
  }
}

export const getPersistedItem = (name: string) => {
  if (typeof window === 'undefined') return

  try {
    const isRememberMe = getIsRememberMe()
    const storage = isRememberMe ? localStorage : sessionStorage

    const data = storage.getItem(name)

    if (data) {
      // eslint-disable-next-line
      return JSON.parse(data)
    }

    // eslint-disable-next-line
    return null
  } catch (err: any) {
    throw new Error(err)
  }
}

export const createShouldForwardProp = (excludedProps: string[]) => {
  return (prop: PropertyKey) => !excludedProps.includes(prop as string)
}

export const restrictedPages: { [key in PackageType]?: PageType[] } = {
  [PackageType.Essential]: [PageType.Explorer],
  [PackageType.Basic]: [PageType.Explorer],
  [PackageType.Premium]: [],
  [PackageType.Performance]: [],
}

export const restrictedFeatures: { [key in PackageType]?: FeatureType[] } = {
  [PackageType.Essential]: [
    FeatureType.ReportingUnit,
    FeatureType.CampaignType,
  ],
  [PackageType.Basic]: [FeatureType.CampaignType],
  [PackageType.Premium]: [],
  [PackageType.Performance]: [],
}

export const isPageAccessible = (
  userPackage: PackageType,
  page: PageType,
  isGlobalView: boolean
): boolean => {
  const restricted = restrictedPages[userPackage] ?? []
  return isGlobalView ? true : !restricted.includes(page)
}

export const isFeatureAccessible = (
  userPackage: PackageType,
  feature: FeatureType,
  isGlobalView: boolean
): boolean => {
  const restricted = restrictedFeatures[userPackage] ?? []
  return isGlobalView ? true : !restricted.includes(feature)
}

export const getNavigationTextColor = (
  isSelect: boolean,
  isAccessible: boolean,
  primaryColor: string,
  disabledColor: string
): string => {
  if (isAccessible && isSelect) {
    return primaryColor
  }

  if (!isAccessible) {
    return disabledColor
  }

  return 'inherit'
}

export const getUserCurrency = (currency: string | undefined) => {
  if (currency === CURRENCY.euro) {
    return '€'
  }

  if (currency === CURRENCY.dollar) {
    return '$'
  }

  return ''
}

export const getColorIfVisible = (
  isVisible: boolean,
  color: string
): string => {
  return isVisible ? color : 'transparent'
}

export const getKpiValueAndSign = (
  kpiData: any,
  kpiFormat: string,
  locale: string,
  currency: string
) => {
  if ((kpiData !== 0 && !kpiData) || typeof kpiData === 'string') {
    return '-'
  }
  if (kpiFormat === KPI_FORMAT.integer) {
    const value = kpiData.toLocaleString(locale, {
      maximumFractionDigits: 0,
    })
    return `${value}`
  }
  if (kpiFormat === KPI_FORMAT.currency) {
    const sign = currency === CURRENCY.dollar ? '$' : '€'
    const value = kpiData.toLocaleString(locale, {
      maximumFractionDigits: 0,
    })
    return `${value} ${sign}`
  }
  if (kpiFormat === KPI_FORMAT.percent) {
    const sign = '%'
    const value = Number(kpiData * 100).toLocaleString(locale, {
      maximumFractionDigits: 2,
    })
    return `${value} ${sign}`
  }
  if (kpiFormat === KPI_FORMAT.time) {
    const sec = Math.floor(kpiData % 60)
    const min = Math.floor((kpiData / 60) % 60)
    const hours = Math.floor(kpiData / 3600)

    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(min).padStart(2, '0')
    const formattedSeconds = String(sec).padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  }

  return ''
}

export const isAnyValueAvailable = <T extends { [key: string]: any }>(
  array: T[],
  key: keyof T
): boolean => {
  return array.some(
    (obj) =>
      obj[key] !== null && obj[key] !== undefined && !Number.isNaN(obj[key])
  )
}

export const getValueKpi3 = (kpi1: number, kpi2: number, dataType: string) => {
  if (kpi2 === 0 || !kpi2) {
    return '-'
  }

  return kpi1 / kpi2
}
