import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { de, enUS } from 'date-fns/locale'
import {
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

import { Kpi } from 'common/constants-metrics'
import { formatDateTick, getFormat } from 'common/helpers-tick-formater'
import { getColorIfVisible, isAnyValueAvailable } from 'common/helpers'

import CustomTooltip from './sources-line-chart-tooltip'
import SourcesLineChartTravellersSelect from './sources-line-chart-travellers-select'
import SourcesLineChartLegend from './sources-line-chart-legend'

import { Box, useTheme } from '@mui/material'

const SourcesLineChart = ({
  chartData,
  kpi1,
  kpi2,
  kpi3,
}: {
  chartData: any
  kpi1: Kpi
  kpi2: Kpi
  kpi3: Kpi
}) => {
  const theme = useTheme()

  const { i18n } = useTranslation()
  const currentlanguage = i18n.language.split('-')[0]
  const locale = currentlanguage === 'de' ? de : enUS

  const {
    value: { currency },
  } = useAppSelector(selectAuth)

  const [selectLines, setSelectLines] = useState({
    kpi1: true,
    kpi2: true,
    kpi3: true,
    isKpi1: isAnyValueAvailable(chartData, kpi1.id),
    isKpi2: isAnyValueAvailable(chartData, kpi2.id),
    isKpi3: isAnyValueAvailable(chartData, kpi3.id),
  })

  const [chartConfig, setChartConfig] = useState({
    data: chartData,
    leftIndex: 0,
    rightIndex: chartData.length - 1,
    refAreaLeft: '',
    refAreaRight: '',
    selectRange: chartData.length,
  })
  const { data, leftIndex, rightIndex, selectRange } = chartConfig

  const handleSelectLine = (line: 'kpi1' | 'kpi2' | 'kpi3') => {
    setSelectLines({
      ...selectLines,
      [line]: !selectLines[line],
    })
  }

  const handleTravellers = (newRange: any) => {
    const travellersRange = newRange.endIndex - newRange.startIndex + 1
    setChartConfig({
      ...chartConfig,
      leftIndex: newRange.startIndex,
      rightIndex: newRange.endIndex,
      selectRange: selectRange === travellersRange ? selectRange : 0,
    })
  }

  const handleRange = (option: number) => {
    const isCustom = option === 0
    const isAll = option === chartData.length
    const startIndex = 0
    const endIndex = isAll ? chartData.length - 1 : option - 1

    if (!isCustom) {
      setChartConfig({
        ...chartConfig,
        leftIndex: startIndex,
        rightIndex: endIndex,
        selectRange: option,
      })
    }
  }

  return (
    <Box sx={{ backgroundColor: 'background.paper', borderRadius: 3 }}>
      <SourcesLineChartLegend
        titleKpi1={kpi1.title}
        titleKpi2={kpi2.title}
        titleKpi3={kpi3.title}
        selectLines={selectLines}
        handleSelectLine={handleSelectLine}
      />
      <Box sx={{ p: { xs: 0.5, md: 2 } }}>
        <ResponsiveContainer width="100%" height={600}>
          <LineChart data={data} margin={{ top: 20 }}>
            <CartesianGrid
              vertical={false}
              stroke={theme.palette.background.default}
            />
            <XAxis
              dataKey="date"
              type="category"
              domain={[leftIndex, rightIndex]}
              tickLine={false}
              axisLine={false}
              tickMargin={24}
              minTickGap={100}
              interval="preserveStartEnd"
              tickFormatter={(tickItem) =>
                formatDateTick(tickItem, leftIndex, rightIndex, locale)
              }
              tick={{
                fontSize: theme.typography.body2.fontSize,
                fill: theme.palette.text.legend,
              }}
              height={80}
            />
            <YAxis
              yAxisId="left-kpi1"
              type="number"
              tickLine={false}
              axisLine={false}
              width={36}
              dx={0}
              dy={-8}
              tickFormatter={getFormat(kpi1.format, currency)}
              tick={{
                fontSize: theme.typography.body2.fontSize,
                fill: getColorIfVisible(
                  selectLines.kpi1,
                  theme.palette.primary.main
                ),
              }}
            />
            <YAxis
              yAxisId="left-kpi2"
              type="number"
              tickLine={false}
              axisLine={false}
              width={36}
              dx={36}
              dy={8}
              tickFormatter={getFormat(kpi2.format, currency)}
              tick={{
                fontSize: theme.typography.body2.fontSize,
                fill: getColorIfVisible(
                  selectLines.kpi2,
                  theme.palette.secondary.main
                ),
              }}
            />
            <YAxis
              yAxisId="right"
              type="number"
              orientation="right"
              tickLine={false}
              axisLine={false}
              tickFormatter={getFormat(kpi3.format, currency)}
              dx={8}
              width={72}
              tick={{
                fontSize: theme.typography.body2.fontSize,
                fill: getColorIfVisible(
                  selectLines.kpi3,
                  theme.palette.tertiary.main
                ),
              }}
            />

            <Tooltip
              content={
                <CustomTooltip
                  kpi1={kpi1}
                  kpi2={kpi2}
                  kpi3={kpi3}
                  selectLines={selectLines}
                  currency={currency}
                />
              }
              cursor={{
                stroke: theme.palette.divider,
                strokeWidth: 1.5,
                strokeDasharray: 4,
              }}
            />
            <Line
              yAxisId="left-kpi1"
              type="linear"
              dataKey="noData"
              isAnimationActive={false}
              strokeWidth={0}
              dot={false}
            />
            <Line
              yAxisId="left-kpi1"
              type="linear"
              dataKey={kpi1.id}
              stroke={getColorIfVisible(
                selectLines.kpi1,
                theme.palette.primary.main
              )}
              strokeWidth={2}
              dot={false}
              activeDot={{
                stroke: theme.palette.background.paper,
              }}
              animationDuration={1200}
            />
            <Line
              yAxisId="left-kpi2"
              type="linear"
              dataKey={kpi2.id}
              stroke={getColorIfVisible(
                selectLines.kpi2,
                theme.palette.secondary.main
              )}
              strokeWidth={2}
              dot={false}
              activeDot={{
                stroke: theme.palette.background.paper,
              }}
              animationDuration={1000}
            />
            <Line
              yAxisId="right"
              type="linear"
              dataKey={kpi3.id}
              stroke={getColorIfVisible(
                selectLines.kpi3,
                theme.palette.tertiary.main
              )}
              strokeDasharray={8}
              strokeWidth={2}
              dot={false}
              activeDot={{
                stroke: theme.palette.background.paper,
              }}
              isAnimationActive={false}
            />

            <Brush
              startIndex={leftIndex}
              endIndex={rightIndex}
              height={86}
              travellerWidth={4}
              stroke="transparent"
              fill={theme.palette.background.paper}
              traveller={<CustomTraveller />}
              onChange={(newRange) => handleTravellers(newRange)}
            >
              <LineChart>
                <XAxis
                  dataKey="date"
                  type="category"
                  domain={[leftIndex, rightIndex]}
                  tickLine={false}
                  axisLine={false}
                  tickMargin={6}
                  minTickGap={100}
                  interval="preserveStartEnd"
                  tickFormatter={(tickItem) =>
                    formatDateTick(tickItem, leftIndex, rightIndex, locale)
                  }
                  tick={{
                    fontSize: theme.typography.caption.fontSize,
                    fill: theme.palette.text.legend,
                  }}
                  height={30}
                />
                <YAxis
                  yAxisId="left-kpi1"
                  type="number"
                  tickLine={false}
                  axisLine={false}
                  tick={false}
                  width={0}
                />
                <YAxis
                  yAxisId="left-kpi2"
                  type="number"
                  tickLine={false}
                  axisLine={false}
                  tick={false}
                  width={0}
                />
                <YAxis
                  yAxisId="right"
                  type="number"
                  orientation="right"
                  tickLine={false}
                  axisLine={false}
                  tick={false}
                  width={0}
                />
                <Line
                  yAxisId="left-kpi1"
                  type="linear"
                  dataKey={kpi1.id}
                  isAnimationActive={false}
                  stroke={getColorIfVisible(
                    selectLines.kpi1,
                    theme.palette.primary.main
                  )}
                  strokeWidth={1}
                  dot={false}
                />
                <Line
                  yAxisId="left-kpi2"
                  type="linear"
                  dataKey={kpi2.id}
                  isAnimationActive={false}
                  stroke={getColorIfVisible(
                    selectLines.kpi2,
                    theme.palette.secondary.main
                  )}
                  strokeWidth={1}
                  dot={false}
                />
                <Line
                  yAxisId="right"
                  type="linear"
                  dataKey={kpi3.id}
                  isAnimationActive={false}
                  stroke={getColorIfVisible(
                    selectLines.kpi3,
                    theme.palette.tertiary.main
                  )}
                  strokeDasharray={2}
                  strokeWidth={1}
                  dot={false}
                />
              </LineChart>
            </Brush>
            <style>{`.recharts-brush-slide { fill: ${theme.palette.background.brush}} `}</style>
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <SourcesLineChartTravellersSelect
        allValue={chartData.length}
        selectRange={selectRange}
        handleRange={handleRange}
      />
    </Box>
  )
}

const CustomTraveller = (props: any) => {
  const theme = useTheme()
  return (
    <rect
      {...props}
      fill={theme.palette.background.brush}
      opacity={0.5}
      strokeWidth={0}
      tabIndex={-1}
      textAnchor="start"
      style={{
        outline: 'none',
      }}
    />
  )
}

export default SourcesLineChart
