import { SECTIONS } from 'common/constants-navigation'

import NavSection from '../nav-section'
import NavigationSubsection from '../nav-subsection'
import NavMobileSubsectionHeader from './nav-mobile-subsection-header'

import { Box, Divider } from '@mui/material'

import { NavMobileWrapper } from './styles'

const NavigationMobile = ({
  navigationConfig,
  handleNavigation,
}: {
  navigationConfig: any
  handleNavigation: any
}) => {
  const inViewSectionDetails = Object.values(SECTIONS).find(
    (section) => section.address === navigationConfig.expandSectionAddress
  )

  return (
    <NavMobileWrapper>
      {!inViewSectionDetails?.subsections ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 1,
          }}
        >
          {Object.values(SECTIONS).map((section) => (
            <Box key={section.address}>
              <NavSection
                sectionConfig={section}
                navigationConfig={navigationConfig}
                handleNavigation={handleNavigation}
              />
              <Divider variant="middle" />
            </Box>
          ))}
        </Box>
      ) : (
        <Box>
          <NavMobileSubsectionHeader
            inViewSectionTitle={inViewSectionDetails.title}
            inViewSectionIcon={inViewSectionDetails.selectIcon}
            navigationConfig={navigationConfig}
            handleNavigation={handleNavigation}
          />
          <Box sx={{ ml: 3, py: 1 }}>
            <NavigationSubsection
              navigationConfig={navigationConfig}
              expandSectionConfig={inViewSectionDetails}
              handleNavigation={handleNavigation}
            />
          </Box>
        </Box>
      )}
    </NavMobileWrapper>
  )
}

export default NavigationMobile
