import { useLayoutEffect, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

import { Kpi } from 'common/constants-metrics'
import { getColorIfVisible, isAnyValueAvailable } from 'common/helpers'
import { getFormat } from 'common/helpers-tick-formater'

import TrendLegend from './trend-chart-legend'
import CustomTrendTooltip from './trend-chart-tooltip'

import { Box, useTheme } from '@mui/material'

const TrendBarChart = ({
  isDimensionYear,
  selectedMonth,
  compareMonth,
  compareYear,
  chartData,
  kpi,
  pastColor,
  currentColor,
}: {
  isDimensionYear: boolean
  selectedMonth: string
  compareMonth: string
  compareYear: string
  chartData: any
  kpi: Kpi
  pastColor: string
  currentColor: string
}) => {
  const theme = useTheme()

  const {
    value: { currency },
  } = useAppSelector(selectAuth)

  const [selectLines, setSelectLines] = useState({
    past: true,
    current: true,
    isPast: isAnyValueAvailable(chartData, `${kpi.id}_past_monthly_total`),
    isCurrent: isAnyValueAvailable(
      chartData,
      `${kpi.id}_current_monthly_total`
    ),
  })

  const handleSelectLine = (line: 'past' | 'current') => {
    setSelectLines({
      ...selectLines,
      [line]: !selectLines[line],
    })
  }

  useLayoutEffect(() => {
    setSelectLines({
      past: true,
      current: true,
      isPast: isAnyValueAvailable(chartData, `${kpi.id}_past_monthly_total`),
      isCurrent: isAnyValueAvailable(
        chartData,
        `${kpi.id}_current_monthly_total`
      ),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData])

  return (
    <Box
      sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
        }}
      >
        <TrendLegend
          selectedMonth={selectedMonth}
          compareMonth={compareMonth}
          compareYear={compareYear}
          pastColor={pastColor}
          currentColor={currentColor}
          selectLines={selectLines}
          handleSelectLine={handleSelectLine}
          isDimensionYear={isDimensionYear}
        />
      </Box>
      <Box sx={{ width: '100%', height: '300px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={500} height={300} data={chartData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month_name"
              interval="preserveStartEnd"
              minTickGap={0}
              tickMargin={12}
              axisLine={false}
              tickLine={false}
              tick={{
                fontSize: theme.typography.body2.fontSize,
                fill: theme.palette.text.legend,
              }}
            />
            <YAxis
              interval="preserveStartEnd"
              minTickGap={80}
              tickLine={false}
              axisLine={false}
              tickFormatter={getFormat(kpi.format, currency)}
              tick={{
                fontSize: theme.typography.body2.fontSize,
                fill: theme.palette.text.legend,
              }}
            />
            <Tooltip
              content={
                <CustomTrendTooltip
                  isDimensionYear={isDimensionYear}
                  kpi={kpi}
                  currency={currency}
                  selectLines={selectLines}
                  pastColor={pastColor}
                  currentColor={currentColor}
                  isBarChart
                />
              }
              cursor={{ fill: theme.palette.background.paper2 }}
            />
            <Bar
              dataKey={`${kpi.id}_past_monthly_total`}
              fill={getColorIfVisible(selectLines.past, pastColor)}
              radius={8}
              barSize={16}
            />
            <Bar
              dataKey={`${kpi.id}_current_monthly_total`}
              fill={getColorIfVisible(selectLines.current, currentColor)}
              radius={8}
              barSize={16}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}
export default TrendBarChart
