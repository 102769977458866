import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getChartDetails } from 'api'
import type { RootState } from 'store/store'

import { STATUS } from 'common/constants'

export const fetchLineChartDetails = createAsyncThunk(
  'chartDetails/fetchLineChartDetails',
  async ({ payload, accessToken }: { payload: any; accessToken: string }) => {
    const chartDetails = await getChartDetails({ payload, accessToken })

    return chartDetails.data
    // return chartDetails
    // return []
  }
)
export const fetchBarChartDetails = createAsyncThunk(
  'chartDetails/fetchBarChartDetails',
  async ({ payload, accessToken }: { payload: any; accessToken: string }) => {
    const chartDetails = await getChartDetails({ payload, accessToken })

    return chartDetails.data
    // return chartDetails
    // return []
  }
)

const initialState: any = {
  value: {
    lineChart: {
      meta: {},
      data: [{ date: '' }],
    },
    barChart: {
      meta: {},
      data: [],
    },
  },
  lineChartStatus: 0,
  barChartStatus: 0,
}

export const chartDetailsSlice = createSlice({
  name: 'chartDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLineChartDetails.pending, (state) => {
        state.lineChartStatus = STATUS.pending
      })
      .addCase(fetchLineChartDetails.fulfilled, (state, { payload }) => {
        state.lineChartStatus = STATUS.fulfilled
        state.value.lineChart = payload
      })
      .addCase(fetchLineChartDetails.rejected, (state) => {
        state.lineChartStatus = STATUS.rejected
      })
      .addCase(fetchBarChartDetails.pending, (state) => {
        state.barChartStatus = STATUS.pending
      })
      .addCase(fetchBarChartDetails.fulfilled, (state, { payload }) => {
        state.barChartStatus = STATUS.fulfilled
        state.value.barChart = payload
      })
      .addCase(fetchBarChartDetails.rejected, (state) => {
        state.barChartStatus = STATUS.rejected
      })
  },
})

export const selectChartDetails = (state: RootState) => state.chartDetails

export default chartDetailsSlice.reducer
