import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

import { getIsRememberMe, persistItem } from 'common/helpers'
import { LOGIN } from 'common/constants-navigation'

import LegalLinks from 'layout/footer/legal-links'

import {
  ApartmentOutlined,
  ArrowCircleUpOutlined,
  Language,
  LogoutRounded,
  SecurityOutlined,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Chip,
  Divider,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material'

const UserMenu = ({ handleClose }: any) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const {
    value: { name: userName },
  } = useAppSelector(selectAuth)

  const handleSignOut = () => {
    const isRememberMe = getIsRememberMe()
    const isLogout = true
    persistItem('auth', null, isRememberMe, isLogout)
    !isRememberMe && persistItem('theme', null, isRememberMe, isLogout)
    window.location.href = `/${LOGIN.address}`
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mb: 4,
          mt: { xs: '-32px', md: 0 },
        }}
      >
        <Avatar
          sx={{
            width: 64,
            height: 64,
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              bgcolor: 'primary.main',
              color: 'primary.contrastText',
              fontWeight: 'bold',
            }}
          >
            {userName?.slice(0, 2).toUpperCase()}
          </Typography>
        </Avatar>
        <Typography
          variant="h6"
          sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
        >
          {t('HI', { ns: 'profile' })} {userName}
        </Typography>
        <Chip
          color="primary"
          variant="outlined"
          label={t('MANAGE_ACCOUNT', { ns: 'profile' })}
          clickable
          sx={{ height: '40px', py: 2, px: 4, borderRadius: '20px' }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 3,
          border: `1px solid ${theme.palette.background.disabled}`,
          py: 1,
        }}
      >
        <MenuItem onClick={handleClose}>
          <ArrowCircleUpOutlined />
          <Typography ml={2}>Upgrade orbit ads</Typography>
        </MenuItem>

        <Divider variant="middle" />

        <MenuItem onClick={handleClose}>
          <SecurityOutlined />
          <Typography ml={2}>Security</Typography>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ApartmentOutlined />
          <Typography ml={2}>Company setup</Typography>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Language />
          <Typography ml={2}>Language</Typography>
        </MenuItem>

        <Divider variant="middle" />

        <MenuItem onClick={handleSignOut}>
          <LogoutRounded />
          <Typography ml={2}>{t('SIGN_OUT', { ns: 'profile' })}</Typography>
        </MenuItem>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          pt: 3,
          pb: 1,
          mt: 'auto',
        }}
      >
        <LegalLinks />
      </Box>
    </Box>
  )
}

export default UserMenu
