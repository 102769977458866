import axios from 'axios'
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { SourcesBarChartMockData, LineChartMockData } from './mock-data'

const { REACT_APP_ORBIT_ADS_URL } = process.env

// eslint-disable-next-line
const API = axios.create({ baseURL: REACT_APP_ORBIT_ADS_URL })

const getConfig = (token: string) => {
  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  }
  return config
}

export const authenticateUser = ({
  email,
  password,
}: {
  email: string
  password: string
}): Promise<{ data: { accessToken: string } }> => {
  // return API.post('/auth', {email, password})

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (email === 'orbit@mso-digital.de' && password === 'orbit345') {
        resolve({
          data: {
            accessToken: 'accessToken345',
          },
        })
      } else {
        reject(new Error('Invalid email or password'))
      }
    }, 2000)
  })
}

export const getClientDetails = (
  accessToken: string
): Promise<{
  data: {
    id: string
    name: string
    email: string
    role: string
    package: string
    agency: string
    language: string
    currency: string
    imageUrl: string
    customers: string[]
  }
}> => {
  // eslint-disable-next-line
  const config = getConfig(accessToken)
  // return API.post('/client/me', config)

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (accessToken === 'accessToken345') {
        resolve({
          data: {
            id: 'some id',
            name: 'first last',
            email: 'email@address.de',
            role: 'editor',
            package: 'basic',
            agency: 'mso digital',
            language: 'de-DE',
            currency: 'EUR',
            imageUrl: 'image url address',
            customers: [
              'Unternehmen XY GmbH & Co. KG',
              'Agentur XY GmbH & Co. KG',
              'Unternehmen XYZ AG',
              'Agentur Muster GmbH & Co. KG',
              'mso digital GmbH & Co. KG',
              'Noch viel viel längerer Kundenname GmbH & Co. KG',
            ],
          },
        })
      } else {
        reject(new Error('Invalid access token'))
      }
    }, 2000)
  })
}

export const getChartDetails = ({
  payload,
  accessToken,
}: {
  payload: any
  accessToken: string
}) => {
  console.log('+++++++++++++++++++++++++++++++')
  console.log(process.env)
  console.log('+++++++++++++++++++++++++++++++')

  return API.post('/api', payload)

  // if (accessToken === 'accessToken345') {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       // resolve(LineChartMockData)
  //       resolve(SourcesBarChartMockData)
  //     }, 2000)
  //   })
  // }

  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     reject(new Error('error'))
  //   }, 3000)
  // })
}
