import React from 'react'
import { useTranslation } from 'react-i18next'
import { format, parseISO } from 'date-fns'
import { de, enUS } from 'date-fns/locale'

import { Check, Close } from '@mui/icons-material'
import { Box, Tooltip, Typography, useTheme } from '@mui/material'

interface Props {
  isSelectedDateCurrentYear?: boolean
  isSelectedDateCurrentMonth?: boolean
  isDimensionYear: boolean
  isAreaChart?: boolean
  selectedMonth: string
  compareMonth: string
  compareYear?: string
  pastColor: string
  currentColor: string
  forecastColor?: string
  selectLines?: { [key: string]: boolean }
  handleSelectLine?: any
}

const TrendLegend: React.FC<Props> = ({
  isSelectedDateCurrentYear,
  isSelectedDateCurrentMonth,
  isDimensionYear,
  isAreaChart,
  selectedMonth,
  compareMonth,
  compareYear,
  pastColor,
  currentColor,
  forecastColor,
  selectLines,
  handleSelectLine,
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const currentlanguage = i18n.language.split('-')[0]
  const locale = currentlanguage === 'de' ? de : enUS

  const kpiData = [
    {
      key: 'past',
      isAvailable: 'isPast',
      date: isDimensionYear && compareYear ? compareYear : compareMonth,
      color: pastColor,
    },
    {
      key: 'current',
      isAvailable: 'isCurrent',
      date: selectedMonth,
      color: currentColor,
    },
    {
      key: 'forecast',
      isAvailable: 'isForecast',
      date: selectedMonth,
      color: forecastColor,
    },
  ] as const

  const isSelectedDateCurrent = isDimensionYear
    ? isSelectedDateCurrentYear
    : isSelectedDateCurrentMonth

  const filterKpiData =
    !isSelectedDateCurrent || !isAreaChart
      ? kpiData?.filter((kpi) => kpi.key !== 'forecast')
      : kpiData

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {filterKpiData.map(({ key, isAvailable, date, color }) => (
        <Tooltip
          key={key}
          title={
            !!selectLines && !selectLines[isAvailable] ? (
              <Box>
                <Typography>{t('NO_RECORDS', { ns: 'common' })}</Typography>
              </Box>
            ) : undefined
          }
          placement="bottom"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'background.paper',
                color: 'text.primary',
                p: 2,
                borderRadius: 3,
                boxShadow: theme.palette.customShadow.popover,
                '& .MuiTooltip-arrow': {
                  color: 'background.paper',
                },
              },
            },
          }}
        >
          <Box
            onClick={() => (selectLines ? handleSelectLine(key) : null)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              cursor: 'pointer',
              color:
                !!selectLines && selectLines[isAvailable]
                  ? color
                  : theme.palette.text.disabled,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: `1px solid ${
                  !!selectLines && selectLines[isAvailable]
                    ? color
                    : theme.palette.text.disabled
                }`,

                borderRadius: 1,
              }}
            >
              {!!selectLines && selectLines[isAvailable] ? (
                <Check
                  sx={{
                    color,
                    opacity: !!selectLines && selectLines[key] ? 1 : 0,
                    fontSize: '12px',
                  }}
                />
              ) : (
                <Close
                  sx={{
                    color: theme.palette.text.disabled,
                    fontSize: '12px',
                  }}
                />
              )}
            </Box>
            {isAreaChart && key === 'forecast' && (
              <Typography variant="body2">
                {t('FORECAST', { ns: 'common' })}
              </Typography>
            )}
            <Typography variant="body2">
              {format(parseISO(date), isDimensionYear ? `yyyy` : `MMM yyyy`, {
                locale,
              })}
            </Typography>
          </Box>
        </Tooltip>
      ))}
    </Box>
  )
}

export default TrendLegend
