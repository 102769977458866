import React, { useState } from 'react'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'
import { setModal } from 'store/features/modal-slice'

import { SIDEBAR_DRAWER_MOBILE, ACCOUNT_MENU } from 'common/constants'
import UserMenu from './user-menu'

import {
  Avatar,
  Box,
  Menu,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'

const HeaderUserMenu = () => {
  const theme = useTheme()

  const dispatch = useAppDispatch()

  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'))
  const {
    value: { name: userName },
  } = useAppSelector(selectAuth)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    isWideScreen
      ? setAnchorEl(event.currentTarget)
      : dispatch(
          setModal({
            type: SIDEBAR_DRAWER_MOBILE,
            activeComponent: ACCOUNT_MENU,
          })
        )
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar
          sx={{
            width: 32,
            height: 32,
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
          }}
        >
          <Typography variant="caption">
            {userName?.slice(0, 2).toUpperCase()}
          </Typography>
        </Avatar>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: '352px',
              maxWidth: '352px',
              mt: 2,
              py: 1,
              px: 2,
              borderRadius: 3,
              boxShadow: theme.palette.customShadow.popover,
              bgcolor: theme.palette.background.paper3,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <UserMenu handleClose={handleClose} />
      </Menu>
    </Box>
  )
}

export default HeaderUserMenu
