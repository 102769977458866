import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

import { KPI_FORMAT } from 'common/constants'

import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { getKpiValueAndSign } from 'common/helpers'

const BarchartTotal = ({
  kpi,
  total,
  format,
  handleSortConfig,
  sortConfig,
}: {
  kpi: { id: string; title: string; format: string }
  total: number
  format: keyof typeof KPI_FORMAT
  handleSortConfig: any
  sortConfig: {
    kpiId: string
    kpiTitle: string
    ascending: boolean
  }
}) => {
  const { t, i18n } = useTranslation()
  const {
    value: { currency },
  } = useAppSelector(selectAuth)
  const currentlanguage = i18n.language.split('-')[0]

  const [isHover, setIsHover] = useState(false)
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => handleSortConfig(kpi.id, kpi.title)}
      >
        <Typography variant="h5" fontWeight="bold" sx={{ mr: 0.5 }}>
          {t(`${kpi.title}`, {
            ns: 'kpi',
          })}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            opacity: sortConfig.kpiId === kpi.id || isHover ? 1 : 0,
          }}
        >
          {sortConfig.kpiId !== kpi.id && (
            <ArrowUpward fontSize="small" cursor="pointer" opacity="0.5" />
          )}
          {sortConfig.kpiId === kpi.id && sortConfig.ascending ? (
            <ArrowUpward fontSize="small" cursor="pointer" />
          ) : (
            <ArrowDownward fontSize="small" cursor="pointer" />
          )}
        </Box>
      </Box>
      <Typography variant="h5" fontWeight="bold">
        {getKpiValueAndSign(total, format, currentlanguage, currency)}
      </Typography>
    </Box>
  )
}

export default BarchartTotal
