import { CUSTOMER_MENU, HEADER } from 'common/constants'

import { Box, styled } from '@mui/material'

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const FirstRowHeaderWrapper = styled(Box)(({ theme }) => ({
  height: HEADER.HEIGHT_MOBILE,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

  [theme.breakpoints.up('md')]: {
    height: HEADER.HEIGHT_DESKTOP,
    borderRadius: theme.spacing(1.5),
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.up('lg')]: {
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
}))

export const SecondRowHeaderWrapper = styled(Box)(({ theme }) => ({
  height: HEADER.HEIGHT_MOBILE,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1.5),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  margin: `0 ${theme.spacing(2)}`,
  [theme.breakpoints.up('md')]: {
    margin: 0,
  },
}))

export const HeaderPanelWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

export const HeaderBreadcrumbWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

export const CustomerSelectWrapper = styled(Box)(({ theme }) => ({
  width: `${CUSTOMER_MENU.WIDTH_DESKTOP_WIDE}px`,
  height: HEADER.HEIGHT_DESKTOP,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'background.paper',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderLeft: `2px solid ${theme.palette.background.default}`,
  borderRight: `2px solid ${theme.palette.background.default}`,
  cursor: 'pointer',

  [theme.breakpoints.up('lg')]: {
    width: `${CUSTOMER_MENU.WIDTH_DESKTOP_EXTRA_WIDE}px`,
  },
}))
