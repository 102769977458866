import { useTranslation } from 'react-i18next'

import ChangeMode from 'components/change-mode'

import { Box, Button, Typography } from '@mui/material'

const Home = () => {
  const { t, i18n } = useTranslation()

  const currentlanguage = i18n.language.split('-')[0]

  const newLanguage = currentlanguage === 'de' ? 'en' : 'de'
  const handleLanguage = () => {
    i18n.changeLanguage(newLanguage)
  }

  return (
    <Box
      sx={{
        minHeight: '500px',
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
        padding: 2,
        backgroundColor: 'background.paper',
        borderRadius: 3,
        mx: { xs: 0.5, md: 0 },
      }}
    >
      <Box>
        <Typography variant="h5">
          {t('WELCOME_TO_ORBIT', { ns: 'common' })}
        </Typography>
        <Button
          onClick={handleLanguage}
          variant="outlined"
          sx={{ minWidth: '136px', borderRadius: '20px', my: 1, mr: 1 }}
        >
          {currentlanguage === 'de'
            ? `${t('ENGLISH', { ns: 'common' })}`
            : `${t('GERMAN', { ns: 'common' })}`}
        </Button>
        <ChangeMode />
      </Box>
    </Box>
  )
}

export default Home
