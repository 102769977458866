import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { authenticateUser, getClientDetails } from 'api'
import type { RootState } from 'store/store'

import { STATUS } from 'common/constants'

export const authenticate = createAsyncThunk(
  'auth/authenticate',
  async (payload: { email: string; password: string }) => {
    const { data } = await authenticateUser({
      email: payload.email,
      password: payload.password,
    })

    const clientResponse = await getClientDetails(data.accessToken)

    return { ...data, ...clientResponse.data }
  }
)

const initialState: any = {
  value: {},
  status: 0,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.value = { ...state.value, ...action.payload }
      state.status = STATUS.idle
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.pending, (state) => {
        state.status = STATUS.pending
      })
      .addCase(authenticate.fulfilled, (state, { payload }) => {
        state.status = STATUS.fulfilled
        state.value = payload
      })
      .addCase(authenticate.rejected, (state) => {
        state.status = STATUS.rejected
      })
  },
})

export const selectAuth = (state: RootState) => state.auth

export const { setAuth } = authSlice.actions

export default authSlice.reducer
