import { useAppDispatch } from 'store/redux-hooks'
import { setModal } from 'store/features/modal-slice'

import { CUSTOMER_SELECT, SIDEBAR_DRAWER_MOBILE } from 'common/constants'

import CustomerSearchSelect from './customer-search-select'

import { Business } from '@mui/icons-material'
import { useTheme, useMediaQuery } from '@mui/material'

const HeaderCustomerSelect = () => {
  const theme = useTheme()

  const dispatch = useAppDispatch()

  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'))

  const handleClick = () => {
    dispatch(
      setModal({
        type: SIDEBAR_DRAWER_MOBILE,
        activeComponent: CUSTOMER_SELECT,
      })
    )
  }

  return !isWideScreen ? (
    <Business onClick={handleClick} sx={{ cursor: 'pointer' }} />
  ) : (
    <CustomerSearchSelect />
  )
}

export default HeaderCustomerSelect
