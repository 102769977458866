import GlobalView from './global-view'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

import { ROLES } from 'common/constants'

import { HeaderUserMenu } from './header-user'
import { HeaderCustomerSelect } from './header-customer'

import { Apps, InfoOutlined } from '@mui/icons-material'

import { HeaderPanelWrapper } from './styles'

const HeaderPanel = () => {
  const {
    value: { role: userRole },
  } = useAppSelector(selectAuth)

  const isMemeber = userRole === ROLES.member

  return (
    <HeaderPanelWrapper>
      {!isMemeber && <HeaderCustomerSelect />}
      {!isMemeber && <GlobalView />}
      <InfoOutlined />
      <Apps />
      <HeaderUserMenu />
    </HeaderPanelWrapper>
  )
}

export default HeaderPanel
