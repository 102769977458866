import { useLayoutEffect, useState } from 'react'

import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from 'recharts'

import { useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'

import { getColorIfVisible, isAnyValueAvailable } from 'common/helpers'
import { swapAccumulatedKpiValues } from 'common/helpers-trend-page'
import { Kpi } from 'common/constants-metrics'
import { getFormat } from 'common/helpers-tick-formater'

import TrendLegend from './trend-chart-legend'
import CustomTrendTooltip from './trend-chart-tooltip'

import { Box, useTheme } from '@mui/material'

const TrendAreaChart = ({
  isSelectedDateCurrentYear,
  isSelectedDateCurrentMonth,
  isDimensionYear,
  selectedMonth,
  compareMonth,
  compareYear,
  chartData,
  kpi,
  pastColor,
  currentColor,
  forecastColor,
}: {
  isSelectedDateCurrentYear: boolean
  isSelectedDateCurrentMonth: boolean
  isDimensionYear: boolean
  selectedMonth: string
  compareMonth: string
  compareYear: string
  chartData: any
  kpi: Kpi
  pastColor: string
  currentColor: string
  forecastColor: string
}) => {
  const theme = useTheme()

  const {
    value: { currency },
  } = useAppSelector(selectAuth)

  const updatedData = swapAccumulatedKpiValues(
    chartData,
    `${kpi.id}_current_accumulated`,
    `${kpi.id}_forecast_accumulated`
  )

  const isSelectedDateCurrent = isDimensionYear
    ? isSelectedDateCurrentYear
    : isSelectedDateCurrentMonth

  const [selectLines, setSelectLines] = useState({
    past: true,
    current: true,
    forecast: true,
    isPast: isAnyValueAvailable(chartData, `${kpi.id}_past_accumulated`),
    isCurrent: isAnyValueAvailable(chartData, `${kpi.id}_current_accumulated`),
    isForecast:
      isSelectedDateCurrent &&
      isAnyValueAvailable(chartData, `${kpi.id}_forecast_accumulated`),
  })

  useLayoutEffect(() => {
    setSelectLines({
      past: true,
      current: true,
      forecast: true,
      isPast: isAnyValueAvailable(chartData, `${kpi.id}_past_accumulated`),
      isCurrent: isAnyValueAvailable(
        chartData,
        `${kpi.id}_current_accumulated`
      ),
      isForecast:
        isSelectedDateCurrent &&
        isAnyValueAvailable(chartData, `${kpi.id}_forecast_accumulated`),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, isSelectedDateCurrent])

  const handleSelectLine = (line: 'past' | 'current' | 'forecast') => {
    setSelectLines({
      ...selectLines,
      [line]: !selectLines[line],
    })
  }

  return (
    <Box
      sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
        }}
      >
        <TrendLegend
          isSelectedDateCurrentYear={isSelectedDateCurrentYear}
          isSelectedDateCurrentMonth={isSelectedDateCurrentMonth}
          compareMonth={compareMonth}
          selectedMonth={selectedMonth}
          compareYear={compareYear}
          pastColor={pastColor}
          currentColor={currentColor}
          forecastColor={forecastColor}
          selectLines={selectLines}
          handleSelectLine={handleSelectLine}
          isDimensionYear={isDimensionYear}
          isAreaChart
        />
      </Box>

      <Box sx={{ width: '100%', height: '300px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={selectLines.forecast ? updatedData : chartData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey={isDimensionYear ? 'month_name' : 'day_number'}
              interval="preserveStartEnd"
              minTickGap={200}
              tickMargin={12}
              axisLine={false}
              tickLine={false}
              tick={{
                fontSize: theme.typography.body2.fontSize,
                fill: theme.palette.text.legend,
              }}
            />
            <YAxis
              interval="preserveStartEnd"
              minTickGap={80}
              tickLine={false}
              axisLine={false}
              tickFormatter={getFormat(kpi.format, currency)}
              tick={{
                fontSize: theme.typography.body2.fontSize,
                fill: theme.palette.text.legend,
              }}
            />
            <Tooltip
              content={
                <CustomTrendTooltip
                  isDimensionYear={isDimensionYear}
                  kpi={kpi}
                  currency={currency}
                  selectLines={selectLines}
                  pastColor={pastColor}
                  currentColor={currentColor}
                  forecastColor={forecastColor}
                  isAreaChart
                />
              }
              cursor={{ fill: theme.palette.background.paper2 }}
            />

            <Area
              type="linear"
              dataKey={`${kpi.id}_current_accumulated`}
              stroke={getColorIfVisible(selectLines.current, currentColor)}
              fill={getColorIfVisible(selectLines.current, currentColor)}
              fillOpacity={1}
              isAnimationActive={false}
            />
            <Area
              type="linear"
              dataKey={`${kpi.id}_forecast_accumulated`}
              stroke={getColorIfVisible(selectLines.forecast, forecastColor)}
              fill={getColorIfVisible(selectLines.forecast, forecastColor)}
              fillOpacity={1}
              isAnimationActive={false}
            />
            <Line
              type="linear"
              dataKey={`${kpi.id}_past_accumulated`}
              stroke={getColorIfVisible(selectLines.past, pastColor)}
              fill={getColorIfVisible(selectLines.past, pastColor)}
              fillOpacity={1}
              strokeDasharray="10 5"
              strokeWidth={4}
              dot={false}
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}
export default TrendAreaChart
