import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material'

const PackageTooltip = ({
  isAccessible,
  children,
}: {
  isAccessible: boolean
  children: any
}) => {
  const theme = useTheme()
  return (
    <Tooltip
      title={
        !isAccessible && (
          <Box>
            <Typography sx={{ fontWeight: 'bold' }}>
              Upgrade to performance!
            </Typography>
            <Typography sx={{ py: 2 }}>
              To be able to see this page, you should upgrade to Pro!
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button variant="contained" sx={{ borderRadius: 6 }}>
                Learn more
              </Button>
            </Box>
          </Box>
        )
      }
      placement="right"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'background.paper',
            color: 'text.primary',
            p: 2,
            borderRadius: 3,
            boxShadow: theme.palette.customShadow.popover,
            '& .MuiTooltip-arrow': {
              color: 'background.paper',
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  )
}

export default PackageTooltip
