import { configureStore } from '@reduxjs/toolkit'

import authSlice from 'store/features/auth-slice'
import themeSlice from './features/theme-slice'
import modalSlice from './features/modal-slice'
import filterSlice from './features/filter-slice'
import chartDetailsSlice from './features/chart-details-slice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    theme: themeSlice,
    modal: modalSlice,
    filter: filterSlice,
    chartDetails: chartDetailsSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
