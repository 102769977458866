import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectTheme, setIsGlobalView } from 'store/features/theme-slice'

import { Visibility, VisibilityOffOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'

const GlobalView = () => {
  const dispatch = useAppDispatch()

  const { isGlobalView } = useAppSelector(selectTheme)

  const handleGlobalView = () => {
    dispatch(setIsGlobalView(!isGlobalView))
  }

  return (
    <Box sx={{ display: 'flex', cursor: 'pointer' }} onClick={handleGlobalView}>
      {isGlobalView ? (
        <Visibility sx={{ color: 'primary.main' }} />
      ) : (
        <VisibilityOffOutlined />
      )}
    </Box>
  )
}

export default GlobalView
