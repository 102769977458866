import { Box } from '@mui/material'

import NavDesktopMini from './nav-desktop-mini'
import NavDesktopOpen from './nav-desktop-open'

const NavDesktop = ({
  isOpenSidebarDrawer,
  navigationConfig,
  handleNavigation,
}: {
  isOpenSidebarDrawer: boolean
  navigationConfig: any
  handleNavigation: any
}) => {
  return (
    <Box>
      {isOpenSidebarDrawer ? (
        <NavDesktopOpen
          handleNavigation={handleNavigation}
          navigationConfig={navigationConfig}
        />
      ) : (
        <NavDesktopMini selectSection={navigationConfig.selectSectionAddress} />
      )}
    </Box>
  )
}

export default NavDesktop
