import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'store/redux-hooks'
import { selectAuth } from 'store/features/auth-slice'
import { setModal } from 'store/features/modal-slice'
import { selectTheme } from 'store/features/theme-slice'

import { HOME } from 'common/constants-navigation'
import { PageType } from 'common/constants'
import { isPageAccessible } from 'common/helpers'

import { NavDesktop } from './nav-desktop'
import { NavMobile } from './nav-mobile'

import { useMediaQuery, useTheme } from '@mui/material'

export interface SelectNavItem {
  selectSection: string
  selectSubsection: string
  selectKpiPath: string
}

interface NavigationProps {
  isOpenSidebarDrawer: boolean
}

export interface NavigationConfigProps {
  expandSectionAddress?: string
  selectSectionAddress?: string
  selectSubsectionAddress?: string
  selectKpiAddress?: string
}

export interface HandleNavigationProps extends NavigationConfigProps {
  action: 'expand' | 'select' | 'back'
  isSubsections?: boolean
}

const Navigation = ({ isOpenSidebarDrawer }: NavigationProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'))
  const { isGlobalView } = useAppSelector(selectTheme)

  const address = pathname.split('/')
  const sectionAddress = address[1]
  const subsectionAddress = address[2]
  const kpiPathAddress = address[3]

  const {
    value: { package: userPackage },
  } = useAppSelector(selectAuth)

  const initialNavigationStatus: NavigationConfigProps = {
    expandSectionAddress: sectionAddress || '',
    selectSectionAddress: sectionAddress || HOME.address,
    selectSubsectionAddress: subsectionAddress
      ? `${sectionAddress}/${subsectionAddress}`
      : '',
    selectKpiAddress: kpiPathAddress
      ? `${sectionAddress}/${subsectionAddress}/${kpiPathAddress}`
      : HOME.address,
  }

  const [navigationConfig, setNavigationConfig] =
    useState<NavigationConfigProps>(initialNavigationStatus)

  useEffect(() => {
    setNavigationConfig(initialNavigationStatus)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleNavigation = ({
    action,
    isSubsections,
    expandSectionAddress,
    selectSectionAddress,
    selectSubsectionAddress,
    selectKpiAddress,
  }: HandleNavigationProps): undefined => {
    const isAccessible = isPageAccessible(
      userPackage,
      selectSectionAddress as PageType,
      isGlobalView
    )

    if (!isAccessible) return

    if (!isSubsections) {
      setNavigationConfig({
        expandSectionAddress: '',
        selectSectionAddress,
        selectSubsectionAddress: '',
        selectKpiAddress,
      })
      !isWideScreen && dispatch(setModal(''))
      selectKpiAddress && navigate(selectKpiAddress)
      return
    }

    if (action === 'expand') {
      setNavigationConfig((prev) => ({
        ...navigationConfig,
        expandSectionAddress:
          prev.expandSectionAddress !== expandSectionAddress
            ? expandSectionAddress
            : '',
      }))
      return
    }

    if (action === 'back') {
      setNavigationConfig({
        ...navigationConfig,
        expandSectionAddress: '',
      })
      return
    }

    if (action === 'select') {
      setNavigationConfig({
        expandSectionAddress,
        selectSectionAddress,
        selectSubsectionAddress,
        selectKpiAddress,
      })
      !isWideScreen && dispatch(setModal(''))
      selectKpiAddress && navigate(selectKpiAddress)
    }
  }

  return isWideScreen ? (
    <NavDesktop
      isOpenSidebarDrawer={isOpenSidebarDrawer}
      navigationConfig={navigationConfig}
      handleNavigation={handleNavigation}
    />
  ) : (
    <NavMobile
      navigationConfig={navigationConfig}
      handleNavigation={handleNavigation}
    />
  )
}

export default Navigation
