import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store/redux-hooks'
import { selectTheme } from 'store/features/theme-slice'
import { selectAuth } from 'store/features/auth-slice'

import { SECTIONS } from 'common/constants-navigation'
import { PageType } from 'common/constants'
import { isPageAccessible } from 'common/helpers'

import { SvgIconComponent } from '@mui/icons-material'
import { Typography } from '@mui/material'

import { NavDesktopMiniSection, NavDesktopMiniWrapper } from './styles'

const NavDesktopMini = ({ selectSection }: { selectSection: string }) => {
  const { t } = useTranslation()

  const {
    value: { package: userPackage },
  } = useAppSelector(selectAuth)

  const { isGlobalView } = useAppSelector(selectTheme)

  const renderIcon = (
    isSelected: boolean,
    Icon: SvgIconComponent,
    SelectIcon: SvgIconComponent
  ) => {
    return isSelected ? <SelectIcon /> : <Icon />
  }

  return (
    <NavDesktopMiniWrapper>
      {Object.values(SECTIONS).map((section) => (
        <NavDesktopMiniSection
          key={section.address}
          isSelect={selectSection === section.address}
          isAccessible={isPageAccessible(
            userPackage,
            section.address as PageType,
            isGlobalView
          )}
        >
          {renderIcon(
            selectSection === section.address,
            section.icon,
            section.selectIcon
          )}
          <Typography
            variant="caption"
            sx={{
              fontWeight: selectSection === section.address ? 700 : 'inherit',
            }}
          >
            {t(`${section.shortTitle}`, { ns: 'navigation' })}
          </Typography>
        </NavDesktopMiniSection>
      ))}
    </NavDesktopMiniWrapper>
  )
}

export default NavDesktopMini
