import { useAppSelector } from 'store/redux-hooks'
import { selectTheme } from 'store/features/theme-slice'
import { selectAuth } from 'store/features/auth-slice'

import { PageType } from 'common/constants'
import { isPageAccessible } from 'common/helpers'

import { SECTIONS } from 'common/constants-navigation'

import PackageTooltip from './package-tooltip'

import { Box, Collapse } from '@mui/material'

import { NavDesktopOpenWrapper } from './styles'
import NavigationSubsection from '../nav-subsection'
import NavSection from '../nav-section'

const NavDesktopOpen = ({
  navigationConfig,
  handleNavigation,
}: {
  navigationConfig: any
  handleNavigation: any
}) => {
  const { isGlobalView } = useAppSelector(selectTheme)
  const {
    value: { package: userPackage },
  } = useAppSelector(selectAuth)

  return (
    <NavDesktopOpenWrapper>
      {Object.values(SECTIONS).map((section) => (
        <Box key={section.address}>
          <PackageTooltip
            isAccessible={isPageAccessible(
              userPackage,
              section.address as PageType,
              isGlobalView
            )}
          >
            <Box>
              <NavSection
                sectionConfig={section}
                navigationConfig={navigationConfig}
                handleNavigation={handleNavigation}
              />
            </Box>
          </PackageTooltip>
          <Collapse
            in={section.address === navigationConfig.expandSectionAddress}
            timeout="auto"
            unmountOnExit
          >
            <Box sx={{ ml: 4, py: 1 }}>
              <NavigationSubsection
                expandSectionConfig={section}
                navigationConfig={navigationConfig}
                handleNavigation={handleNavigation}
              />
            </Box>
          </Collapse>
        </Box>
      ))}
    </NavDesktopOpenWrapper>
  )
}

export default NavDesktopOpen
