import { KPI_FORMAT } from './constants'

export interface Kpi {
  id: string
  title: string
  format: (typeof KPI_FORMAT)[keyof typeof KPI_FORMAT]
}

export const METRICS: { [key: string]: Kpi } = {
  COST: {
    id: 'cost',
    title: 'COST',
    format: KPI_FORMAT.currency,
  },
  CLICKS: {
    id: 'clicks',
    title: 'CLICKS',
    format: KPI_FORMAT.integer,
  },
  LEADS: {
    id: 'conversions',
    title: 'LEADS',
    format: KPI_FORMAT.integer,
  },
  REVENUE: {
    id: 'conversion_value_order',
    title: 'REVENUE',
    format: KPI_FORMAT.currency,
  },
  COST_PER_MILLE: {
    id: 'cost_per_mille',
    title: 'COST_PER_MILLE',
    format: KPI_FORMAT.currency,
  },
  COST_PER_LEAD: {
    id: 'cost_per_lead',
    title: 'COST_PER_LEAD',
    format: KPI_FORMAT.currency,
  },
  AVG_ORDER_VALUE: {
    id: 'avg_order_value',
    title: 'AVERAGE_ORDER_VALUE',
    format: KPI_FORMAT.currency,
  },
  IMPRESSIONS: {
    id: 'impressions',
    title: 'IMPRESSIONS',
    format: KPI_FORMAT.integer,
  },
  ENGAGED_SESSIONS: {
    id: 'engaged_sessions',
    title: 'ENGAGED_SESSIONS',
    format: KPI_FORMAT.integer,
  },
  SESSIONS_GA: {
    id: 'sessions',
    title: 'SESSIONS_GA',
    format: KPI_FORMAT.integer,
  },
  SESSION_DURATION: {
    id: 'session_duration',
    title: 'SESSION_DURATION',
    format: KPI_FORMAT.time,
  },
  AVG_SESSION_DURATION: {
    id: 'avg_session_duration',
    title: 'AVG_SESSION_DURATION',
    format: KPI_FORMAT.time,
  },
  ENGAGEMENT_RATE: {
    id: 'engagement_rate',
    title: 'ENGAGEMENT_RATE',
    format: KPI_FORMAT.percent,
  },
  COST_REVENUE_RATIO: {
    id: 'cost_revenue_ratio',
    title: 'COST_REVENUE_RATIO',
    format: KPI_FORMAT.percent,
  },
  CONVERSION_RATE: {
    id: 'conversion_rate',
    title: 'CONVERSION_RATE',
    format: KPI_FORMAT.percent,
  },
  CONVERSION_RATE_E_COMMERCE: {
    id: 'conversion_rate_ecom',
    title: 'CONVERSION_RATE',
    format: KPI_FORMAT.percent,
  },
  CLICK_THROUGH_RATE: {
    id: 'click_through_rate',
    title: 'CLICK_THROUGH_RATE',
    format: KPI_FORMAT.percent,
  },
  CLICK_THROUGH_RATE_BRANDING: {
    id: 'click_through_rate',
    title: 'CLICK_THROUGH_RATE',
    format: KPI_FORMAT.percent,
  },
  CLICK_THROUGH_RATE_E_COMMERCE: {
    id: 'click_through_rate',
    title: 'CLICK_THROUGH_RATE',
    format: KPI_FORMAT.percent,
  },
}
